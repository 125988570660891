import {Swiper, EffectCards, Keyboard} from 'swiper';
import MicroModal from 'micromodal';  // es6 module
import Parallax from 'parallax-js'
import inView from 'in-view';

Swiper.use([EffectCards, Keyboard]);
MicroModal.init({
	simulateTouch: false,
	preventClicks: false,
	disableScroll: true,
	onShow: modal => {
		document.body.classList.add('modal-open');
		/*let video = document.querySelector('.responsive-video iframe');
		if (video) {
			video.src = video.dataset.src;
		}*/
	},
	onClose: modal => {
		document.body.classList.remove('modal-open');
		let video = document.querySelector('.responsive-video iframe');
		/*if (video) {
			video.src = '';
		}*/
		video.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
	},
});

window.addEventListener('readystatechange', loadAnimations);

document.addEventListener("DOMContentLoaded", () => {
	const eventTag = document.querySelectorAll('.eventTag');
	for (var i = 0; i < eventTag.length; i++) {
		eventTag[i].addEventListener('click', sendEvent)
	}

	const downloadGuideButton = document.querySelector('#guide-success-content a.pill-button');
	if (downloadGuideButton) {
		console.log(downloadGuideButton);
		downloadGuideButton.addEventListener('click',(event) => {
			let element = event.currentTarget;
			dataLayerPush(element.dataset.eventname, element.dataset.eventlocation);
		})
	}
});

function loadAnimations() {
	document.body.classList.add('reveal-hide');
}

setTimeout(() => {
	inView('.reveal')
		.on('enter', el => {
			el.classList.add('shown')
		})
		.on('exit', el => {
			//el.classList.remove'shown')
		});

},500);

const swiper = new Swiper('.hero-slider', {
	effect: 'cards',
	cardsEffect: {
		perSlideOffset:	50,
		slideShadows: false,
		rotate: false,
	},
	grabCursor: true,
	keyboard: {
		enabled: true,
		onlyInViewport: true,
	},
	initialSlide: 1
});

swiper.on('realIndexChange', function () {
	let buttonText = this.slides[this.realIndex].dataset.buttontext;
	let buttonLink = this.slides[this.realIndex].dataset.buttonlink;

	let swiperButton = document.querySelector('.swiper-button');
	if (swiperButton) {
		swiperButton.href = buttonLink;
		let swiperButtonText = swiperButton.querySelector('strong');
		if (swiperButtonText) {
			swiperButtonText.textContent = buttonText;
		}
	}
});

const sliderPrev = document.querySelector('.slider-nav-prev');
const sliderNext = document.querySelector('.slider-nav-next');

if (sliderPrev) {
	sliderPrev.addEventListener('click', (event) => {
		event.preventDefault();
		swiper.slidePrev();
	})
}
if (sliderNext) {
	sliderNext.addEventListener('click', (event) => {
		event.preventDefault();
		swiper.slideNext();
	})
}

document.addEventListener('DOMContentLoaded', () => {
	document.body.classList.add('loaded');
});

const heroSelector = document.querySelector('.select-hero');
if (heroSelector) {
	const heroSelectorButton = heroSelector.querySelector('.hero-selector');
	heroSelectorButton.addEventListener('click', (event) => {
		event.preventDefault();
		heroSelector.classList.toggle('open');
	})
}

const renewalForm = document.getElementById('renewal-form');
if (renewalForm) {
	renewalForm.addEventListener('submit', submitRenewal)
}

function submitRenewal(event) {
	event.preventDefault();
	const form = event.currentTarget;

	dataLayerPush('Form submit', 'Retail - Renewal Reminder')

	let csrf = document.querySelector('meta[name="csrf-token"]')['content'];
	let formURL = form.dataset.endpoint;

	//Select input values with the data you want to send
	let first_name = form.querySelector('input[name="first_name"]').value;
	let last_name = form.querySelector('input[name="last_name"]').value;
	let email_address = form.querySelector('input[name="email_address"]').value;
	let telephone = form.querySelector('input[name="telephone"]').value;
	let renewal_date = form.querySelector('input[name="renewal_date"]').value;
	let terms = (form.querySelector('input[name="terms"]').checked ? 1 : null);
	let marketing = (form.querySelector('input[name="marketing"]').checked ? 1 : null);
	let form_page = form.querySelector('input[name="form-page"]').value;

	fetch(formURL, {
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json, text-plain, */*",
			"X-Requested-With": "XMLHttpRequest",
			"X-CSRF-TOKEN": csrf
		},
		method: 'post',
		credentials: "same-origin",
		body: JSON.stringify({
			first_name: first_name,
			last_name: last_name,
			email_address: email_address,
			telephone: telephone,
			renewal_date: renewal_date,
			terms: terms,
			marketing: marketing,
			form_page: form_page
		})
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			}
			if (response.status === 500) {
				clearRenewalErrors();
				return false;
			}
			return Promise.reject(response); // 2. reject instead of throw
		})
		.then(data => {
			if (data.success === true) {
				form.reset();
				clearRenewalErrors();
				renewalSuccess();
			} else {
				renewalError();
			}
		})
		.catch((response) => {
			response.json().then((json) => {
				clearRenewalErrors();
				renewalErrors(json);
			})
		});
}

function renewalSuccess() {
	const errorP = document.createElement("p");
	errorP.classList.add('error');
	const errorP_node = document.createTextNode('Your details have been submitted, we will be in touch shortly.');
	errorP.append(errorP_node);
	if (renewalForm) {
		renewalForm.after(errorP);
		renewalForm.remove();
	}
}
function renewalError() {
	const errorP = document.createElement("p");
	errorP.classList.add('error');
	const errorP_node = document.createTextNode('There was a problem submitting the form, please try again.');
	errorP.append(errorP_node);
	if (renewalForm) {
		renewalForm.append(errorP);
	}
}
function renewalErrors(json) {
	if (json.errors) {
		for (const error in json.errors) {
			let inputElement = renewalForm.querySelector('[name="' + error + '"]');
			if (inputElement) {
				const errorP = document.createElement("p");
				errorP.classList.add('error');
				const errorP_node = document.createTextNode(json.errors[error]);
				errorP.append(errorP_node);
				inputElement.parentNode.classList.add('error');
				inputElement.parentNode.append(errorP);
			}
		}
	}
}
function clearRenewalErrors() {
	const allErrors = document.querySelectorAll('.form-field.error');
	for (var i = 0; i < allErrors.length; i++) {
		allErrors[i].classList.remove('error');
		/*allErrors[i].parentNode.parentNode.querySelector('p.error').remove();*/
		allErrors[i].querySelector('p.error').remove();
	}
}

const downloadGuide = document.getElementById('download-guide');
if (downloadGuide) {
	downloadGuide.addEventListener('submit', submitdownloadGuide)
}

function submitdownloadGuide(event) {
	event.preventDefault();
	const form = event.currentTarget;

	let csrf = document.querySelector('meta[name="csrf-token"]')['content'];
	let formURL = form.dataset.endpoint;

	//Select input values with the data you want to send
	let email_address = form.querySelector('input[name="email_address"]').value;
	let guide_page = form.querySelector('input[name="guide_page"]').value;

	console.log(form.querySelector('input[name="guide_page"]').value);
	dataLayerPush('Form submit', 'Food Drink - Hospitality calendar');
	fetch(formURL, {
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json, text-plain, *!/!*",
			"X-Requested-With": "XMLHttpRequest",
			"X-CSRF-TOKEN": csrf
		},
		method: 'post',
		credentials: "same-origin",
		body: JSON.stringify({
			email_address: email_address,
			form_page: guide_page
		})
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			}
			if (response.status === 500) {
				clearDownloadGuideErrors();
				return false;
			}
			return Promise.reject(response); // 2. reject instead of throw
		})
		.then(data => {
			if (data.success === true) {
				form.reset();
				clearDownloadGuideErrors();
				downloadGuideSuccess(data.downloadUrl);
			} else {
				downloadGuideError();
			}
		})
		.catch((response) => {
			response.json().then((json) => {
				clearDownloadGuideErrors();
				downloadGuideErrors(json);
			})
		});
}
function downloadGuideSuccess(pdfUrl) {
	const errorP = document.createElement("p");
	errorP.classList.add('error');
	const errorP_node = document.createTextNode('Thank you for your interest, your PDF should load in a new window shortly, please make sure you allow popups from this website.');
	errorP.append(errorP_node);
	if (downloadGuide) {
		downloadGuide.after(errorP);
		downloadGuide.remove();
	}
	setTimeout(() => {
		window.open(pdfUrl, '_blank');
	},1000)
}
function downloadGuideError() {
	const errorP = document.createElement("p");
	errorP.classList.add('error');
	const errorP_node = document.createTextNode('There was a problem submitting the form, please try again.');
	errorP.append(errorP_node);
	if (downloadGuide) {
		downloadGuide.append(errorP);
	}
}
function downloadGuideErrors(json) {
	if (json.errors) {
		for (const error in json.errors) {
			let inputElement = downloadGuide.querySelector('[name="' + error + '"]');
			if (inputElement) {
				const errorP = document.createElement("p");
				errorP.classList.add('error');
				const errorP_node = document.createTextNode(json.errors[error]);
				errorP.append(errorP_node);
				inputElement.parentNode.classList.add('error');
				inputElement.parentNode.append(errorP);
			}
		}
	}
}

function clearDownloadGuideErrors() {
	const allErrors = document.querySelectorAll('.form-field.error');
	for (var i = 0; i < allErrors.length; i++) {
		allErrors[i].classList.remove('error');
		allErrors[i].querySelector('p.error').remove();
	}
}

var scene = document.getElementById('scene');
if (scene) {
	var parallaxInstance = new Parallax(scene, {
		calibrateX: true,
		limitY: 0,
		limitX: 50,
		pointerEvents: true,
	});
}

function sendEvent(event) {
	let element = event.currentTarget;
	event.preventDefault();
	window.dataLayer = window.dataLayer || [];
	dataLayerPush(element.dataset.eventname, element.dataset.eventlocation);
}

function dataLayerPush(name, location) {
	dataLayer.push({
		event: name,
		eventLocation: location
	});
	console.log(dataLayer);
}

const hospitalityCalendarButton = document.getElementById('hospitalityCalendarButton');
if (hospitalityCalendarButton) {
hospitalityCalendarButton.addEventListener('click', function(event) {
	dataLayerPush('Modal content button click', 'Food Drink - Hospitality Calendar PDF');
});
}
